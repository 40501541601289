'use strict';

const baseMenu = require('brand_core/components/menu');
const keyboardNav = require('./headerKeyboardNav');
const countrySelectorKeyboardNav = require('brand_core/components/countrySelectorKeyboardNav');
const breakpoints = require('../components/breakpoints');
const debounce = require('lodash/debounce');

const $sitenav = $('.sitenav');
const $sitenavMenu = $('.sitenav__menu');

/**
 * Load Account Icon's Greeting if the user is Logged In
 *
 * This function dynamically updates the account icon greeting to include the user's first name.
 * It is used to avoid caching issues that prevent conditional changes in the ISML template.
 */
function loadAccountIconGreeting() {
    if (!(window.clientData &&
            window.clientData.user &&
            window.clientData.user.permissionsForCurrentAuthState &&
            window.clientData.user.permissionsForCurrentAuthState.canViewBasicPersonalInfo &&
            window.clientData.user.firstName)
    ) {
        return;
    }

    const $guestIcon = $('.js-guest-icon');
    const $registeredIcon = $('.js-registered-icon');
    const firstName = window.clientData.user.firstName;

    $guestIcon.removeClass('d-lg-block');
    $registeredIcon.addClass('d-lg-block').append(`${firstName}`);
}

/**
 * Open the mobile navigation menu
 * @param {string} openDrawerTarget registration or login drawer selector
 */
function openMobileNav(openDrawerTarget) {
    baseMenu.lazyLoadAccountContent(openDrawerTarget);

    // Prevent page scrolling
    $.disableScroll();
    $('html').addClass('menu-active');
    $sitenav.addClass('open');
    $sitenavMenu.addClass('open');
}

/**
 * Events triggered on screen size
 */
function resizeEvents() {
    let lastWidth = $(window).width();
    const isWidthResized = debounce(function () {
        if ($(window).width() !== lastWidth) {
            lastWidth = $(window).width();
            $(window).trigger('widthResize');
        }
    }, 300);

    const reloadEvents = function () {
        // clear all breakpoint dependent events
        $('*').off('.breakpointDependent');
        baseMenu.mouseEvents();
        baseMenu.miscEvents();

        if (breakpoints.isHighRes()) {
            baseMenu.closeMobileNav();
        }
    };

    // reload events on screen resize
    $(window).on('resize.reloadEvents', isWidthResized);
    $(window).on('widthResize.reloadEvents', reloadEvents);
}

/**
 * Initialize functions
 */
function init() {
    baseMenu.mouseEvents();
    baseMenu.keyboardEvents();
    keyboardNav.init();
    countrySelectorKeyboardNav.init();
    baseMenu.desktopTapEvents();
    baseMenu.miscEvents();
    baseMenu.scrollEvents();
    resizeEvents();
    loadAccountIconGreeting();
}

module.exports = function () {
    // baseMenu function overrides
    baseMenu.openMobileNav = openMobileNav;
    // additional functions
    init();
};
